import React from "react";
import { graphql } from "gatsby";
import LayoutComponent from "../components/global/layout";
import SectionContainerComponent from "../components/containers/section";
import SEOComponent from "../components/global/seo";
import BlogPostDetailComponent from "../components/blog/post-detail";

const BlogPostLayout = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <LayoutComponent>
      <SectionContainerComponent>
        <SEOComponent 
          title={markdownRemark.frontmatter.title}
          metaDescription={markdownRemark.frontmatter.summary} 
          ogType={`article`} 
          shareImage={markdownRemark.frontmatter.socialImage} 
          shareImageIsLarge={markdownRemark.frontmatter.socialImageIsLarge} 
          canonicalUrl={markdownRemark.fields.slug} />
        <BlogPostDetailComponent data={data} />
      </SectionContainerComponent>
    </LayoutComponent>
  )
}

export default BlogPostLayout

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        summary
        date
        disqusId
        categories {
          name
          slug
        }
        tags
        teaserImage
        socialImage
        socialImageIsLarge
      }
      timeToRead
    }
  }
`