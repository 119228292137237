import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import React, { useState } from 'react';
import { Disqus } from 'gatsby-plugin-disqus';

const DisqusComponent = ({ postId, postTitle, postUrl }) => {
  const [disqusIsVisible, setDisqusVisibility] = useState(false);

  const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `
    )

    const showCommentsClick = event => {
      setDisqusVisibility(true);
    };

    let disqusConfig = {
        url: `${site.siteMetadata.siteUrl+postUrl}`,
        identifier: postId,
        title: postTitle,
    }

    return (
      <>
        <div className="pt-6 pb-6 text-md text-gray-700 dark:text-gray-300">
          <h2 className="text-3xl font-bold mb-1 tracking-tight">Before you go...</h2>
          <p className="prose text-gray-500 max-w-none dark:text-gray-400">If you've found this post helpful, you can buy me a coffee. It's certainly not necessary but much appreciated!</p>
          <p className="pt-2 text-center">
            <a href="https://www.buymeacoffee.com/surinderbhomra" target="_blank" rel="noreferrer">
              <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" width="210" height="60" className="inline" />
            </a>
          </p>
        </div>
        <div className="pt-6 pb-6 text-md text-gray-700 dark:text-gray-300">
          <h2 className="text-3xl font-bold mb-1 tracking-tight">Leave A Comment <i className="twa twa-thumbs-up"></i></h2>
          <p className="prose text-gray-500 max-w-none dark:text-gray-400">
            <em>If you have any questions or suggestions, feel free to leave a comment. I do get inundated with messages regarding my posts via LinkedIn
            and leaving a comment below is a better place to have an open discussion. Your comment will not only help others, but also myself.</em>
          </p>
        </div>
        {!disqusIsVisible && (
          <div className="pt-6 pb-6 text-center">
            <button className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400" onClick={showCommentsClick}>Load Comments <i className="twa twa-lg twa-speech-bubble"></i></button>
          </div>
        )}
        {disqusIsVisible && (
          <Disqus className="border-none" config={disqusConfig} />
        )}
      </>
    )
}

DisqusComponent.propTypes = {
    postId: PropTypes.string,
    postTitle: PropTypes.string,
    postUrl: PropTypes.string
}

export default DisqusComponent